
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { ICredentialSet } from "@/interfaces/api/v2/credential-set.interface";
import { ICredentialProof } from "@/interfaces/api/v3/credential-verified.interface";
import { IFormattedTopic } from "@/interfaces/api/v2/topic.interface";
import {
  ICredential,
  ICredentialFormatted,
} from "@/interfaces/api/v4/credential.interface";
import i18n from "@/i18n/index";
import router from "@/router";
import moment from "moment";
import BackTo from "@/components/shared/BackTo.vue";
import { unwrapTranslations, isExpired } from "@/utils/entity";

interface Data {
  headers: Record<string, string>[];
}

@Component({
  components: {
    BackTo,
  },
  computed: {
    ...mapGetters([
      "mdiArrowLeft",
      "mdiShieldCheckOutline",
      "mdiCheckBold",
      "selectedTopic",
      "selectedTopicFullCredentialSet",
      "getSelectedCredential",
      "getPresentationId",
      "getPresentationEX",
      "loading",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchSelectedCredential",
      "setLoading",
      "fetchPresId",
      "fetchPresEx",
      "fetchFormattedIdentifiedTopic",
    ]),
  },
})
export default class CredentialDetail extends Vue {
  selectedTopic!: IFormattedTopic;
  selectedTopicFullCredentialSet!: Array<ICredentialSet>;
  getSelectedCredential!: ICredentialFormatted | undefined;
  loading!: boolean;
  getPresentationId!: string;
  getPresentationEX!: ICredentialProof;
  sourceId!: string;
  isExpired = isExpired;
  fetchSelectedCredential!: (id: string) => Promise<void>;
  fetchPresId!: (id: string) => Promise<void>;
  fetchPresEx!: (params: { id: string; presId: string }) => Promise<void>;
  fetchFormattedIdentifiedTopic!: ({
    sourceId,
    type,
  }: {
    sourceId: string;
    type: string;
  }) => Promise<void>;
  setLoading!: (loading: boolean) => void;

  data(): Data {
    return {
      headers: [
        { text: "attr_name", align: "start", value: "attr_name" },
        { text: "attr_val", align: "start", value: "attr_val" },
      ],
    };
  }

  get currDate(): string {
    return moment(new Date()).format("MMM, DD YYYY [at] hh:mm a");
  }

  get entityName(): string | undefined {
    return this.selectedTopic.names[0]?.text;
  }

  get entitySourceID(): string | undefined {
    return this.sourceId;
  }

  get currCredEffDate(): Date | undefined {
    return this.getSelectedCredential?.effective_date;
  }

  get currCredIssuedDate(): Date | undefined {
    return this.getSelectedCredential?.create_timestamp;
  }

  get currCredIssuer(): string | undefined {
    return this.getSelectedCredential?.credential_type?.issuer?.name;
  }

  get currCredTypeDesc(): string | undefined {
    if (!this.getSelectedCredential) {
      return undefined;
    }
    let credDesc = this.getSelectedCredential.credential_type.description;
    if (
      unwrapTranslations(
        this.getSelectedCredential.credential_type.schema_label
      )?.[i18n.locale]?.label
    ) {
      credDesc = (
        unwrapTranslations(
          this.getSelectedCredential.credential_type.schema_label
        ) as Record<string, { label: string; description: string }>
      )[i18n.locale].label;
    }
    return credDesc;
  }

  get credRevoked(): boolean | undefined {
    return (
      this.getSelectedCredential?.revoked ||
      !!this.isExpired(this.getSelectedCredential?.attributes)
    );
  }

  get credRevokedDate(): Date | string | undefined {
    return (
      this.getSelectedCredential?.revoked_date ||
      this.isExpired(this.getSelectedCredential?.attributes)
    );
  }

  get errorWording(): string {
    if (!this.credRevoked) {
      return "";
    }
    // Credential is expired and has been replaced. It can no longer be verified. (replaced and expired)
    // Credential is expired. It can no longer be verified. (expired)
    // Credential has been replaced. It can no longer be verified. (replaced)
    const replaced = !!this.getSelectedCredential?.revoked;
    const expired = !!this.isExpired(this.getSelectedCredential?.attributes);
    let base = "Credential ";
    if (expired) {
      base += "is expired";
      if (replaced) {
        base += " and ";
      } else {
        base += ".";
      }
    }
    if (replaced) {
      base += "has been replaced.";
    }
    return base + " It can no longer be verified";
  }

  get proofValues(): Record<string, string>[] | undefined {
    const rawVals =
      this.getPresentationEX?.result?.presentation?.requested_proof
        ?.revealed_attr_groups?.["self-verify-proof"]?.values;
    if (rawVals === undefined) {
      return rawVals;
    }
    return Object.keys(rawVals).map((key) => {
      return { attr_name: key, attr_val: rawVals[key].raw };
    });
  }

  get proofRaw(): string | undefined {
    const rawVals = this.getPresentationEX;
    if (rawVals === undefined) {
      return rawVals;
    }
    return JSON.stringify(rawVals?.result, null, 2);
  }

  isRelationshipCred(cred: ICredential): boolean {
    return cred.credential_type.description === "relationship.registries.ca";
  }

  // FIXME: Need to fix timing issue in the API first
  async created(): Promise<void> {
    this.setLoading(true);
    const { sourceId, credentialId } = this.$route.params;
    this.sourceId = sourceId;
    if (sourceId && credentialId) {
      await Promise.all([
        this.fetchSelectedCredential(credentialId),
        this.fetchPresId(credentialId),
        this.fetchFormattedIdentifiedTopic({
          sourceId,
          type: "registration.registries.ca",
        }),
      ]);
      //need a small timeout because the credential isn't always verified after fetchPresId returns
      await new Promise((r) => setTimeout(r, 1000));
      await this.fetchPresEx({
        id: credentialId,
        presId: this.getPresentationId,
      });
    } else {
      router.push("/search");
    }
    if (!this.getSelectedCredential || !this.selectedTopic) {
      // cred or source id invalid, failed to load credential
      console.error(
        "Invalid credential or source Id. Credential detail not retrieved"
      );
      router.push("/search");
    }
    this.setLoading(false);
  }
}
